import React from 'react';
import { Link } from "gatsby"

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

const Button = ({ children, className = '', size }) => {
  return (
    <Link
      type="button"
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        rounded-large
        font-bold
        bg-white
        hover:bg-primary-white
        rounded
        text-black
       `}
     to="https://funee.notion.site/FUNEE-8a2130650ec7405b842773eac6239e81"
    >
      {children}
    </Link>
  );
};

export default Button;
