import React from "react";
import Button from "../components/Button";
import Layout from "../components/layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Section } from "../components/Section";
import { MetaData } from "../components/MetaData";
import { StaticImage } from "gatsby-plugin-image";
const Index = ({ location }) => {
  return (
    <>
      <MetaData />
      <Layout location={location}>
        <section
          id="home"
          className="bg-home bg-cover lg:h-screen lg:pb-0 py-16 flex items-center"
        >
          <div className="container mx-auto lg:px-8 px-6">
            <div className="lg:mb-16 mb-8">
              <p className="mb-4 text-2xl lg:text-2xl mt-6 font-bold font-light text-white">
                Our vision
              </p>
              <h2 className=" text-lg lg:text-4xl xl:text-4xl font-bold leading-none text-white">
                アパレル市場の生態系をアップデートする
              </h2>
            </div>
            <div>
              <p className="mb-4 text-2xl lg:text-2xl mt-6 font-bold font-light text-white">
                Our mission
              </p>
              <h2 className="text-lg lg:text-4xl xl:text-4xl font-bold leading-none text-white">
                お洒落を持続可能に
              </h2>
            </div>
          </div>
        </section>
        <Section title={"Our Service"} id={"service"} bgClass="bg-service">
          <div className="container mx-auto lg:flex lg:flex-col">
            <div className="mb-16">
              <h3 className="text-2xl lg:text-2xl font-bold font-light">
                ファッションメディアの運営
              </h3>
              <p className="text-sm lg:text-sm mt-0 text-gray-600">
                SNSを主軸に合計50万フォロワーのメディアを運営しています。
              </p>
            </div>
            <div className="mb-16">
              <h3 className="text-2xl lg:text-2xl font-bold font-light">
                ファッションプラットフォームの開発・運営
              </h3>
              <p className="text-sm lg:text-sm mt-0 text-gray-600">
                不要な洋服を簡単に売買できるファッションサービスを提供しています
              </p>
            </div>
            <div className="mb-16">
              <h3 className="text-2xl lg:text-2xl font-bold font-light">
                Instagramの運用コンサルティング
              </h3>
              <p className="text-sm lg:text-sm mt-0 text-gray-600">
                自社のInstagram運用ノウハウを軸に、企業様の目的にあったInstagram運用コンサルティングを提供しています。
              </p>
            </div>
          </div>
        </Section>
        <Section title={"Recruit"} id={"recruit"} bgClass="bg-gray-200">
          <div className="container mx-auto lg:flex lg:flex-col text-center">
            <p className="text-xl font-light font-bold mb-12">
              FUNEEでは、日常にワクワクが溢れる世界を目指す仲間を募集しています。
            </p>
            <div className="mb-12">
              <StaticImage
                src="../images/cover_recruit.jpg"
                alt="cover_recruit"
                className="w-4/5"
              />
            </div>
            <div className="mb-12">
              <Button className="w-4/5">
                <text>募集職種一覧を見る</text>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Button>
            </div>
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default Index;
