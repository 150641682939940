import React from "react";
/** セクション */
export const Section = ({ children, title, bgClass, id }) => {
  return (
    <section
      id={id}
      className={`py-4 lg:py-0 bg-cover lg:px-8 px-4 ${bgClass}`}
    >
      <div className="container mx-auto text-center">
        <h2 className="text-2xl lg:text-5xl font-semibold py-4 md:py-8 lg:py-16">
          {title}
        </h2>
      </div>
      {children}
    </section>
  );
};
