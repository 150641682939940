import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import ogp_image from "../images/ogp_image.jpg";

export const MetaData = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteDescription
          siteUrl
          siteLanguage
        }
      }
    }
  `);
  const { siteUrl ,siteTitle, siteDescription, siteLanguage } =  data.site.siteMetadata
  const defaultImage = `${siteUrl}${ogp_image}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: siteLanguage,
      }}
      title={siteTitle}
      meta={[
        {
          name: `description`,
          content: siteDescription,
        },
        {
          property: `op:title`,
          content: siteTitle
        },
        {
          property: `og:description`,
          content: siteDescription

        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: defaultImage
        },
        {
          property: `twitter:card`,
          content: `summary_large_image`
        },
        {
          property: `twitter:url`,
          content: siteUrl
        },
        {
          property: `twitter:title`,
          content: siteTitle
        },
        {
          property: `twitter:description`,
          content: siteDescription
        },
        {
          property: `twitter:image`,
          content: defaultImage
        },
        // google search console のため
        {
          name: `google-site-verification`,
          content: `LjlQAovvfdtr9KOmj42tpAucwUQlfJPNO9jNcWtPNys`
        }
      ]}
    />
  );
};
